import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Layout } from "../components";
import { AgenciesSection } from "../style";
import { SingleSwingRide } from "../components/SingleSwingRide";

const SwingRide = ({
    location = {},
}) => {
    const [rideDetails, setRideDetails] = useState(location?.state?.data);

    useEffect(() => {
        console.log("Location", location);
    }, [])

    return (
        <Layout>
            <AgenciesSection>
                <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                    <p
                        onClick={() => {
                            typeof history !== "undefined" && history.go(-1);
                        }}
                        style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            fontSize: "0.7rem",
                            marginBottom: "1rem",
                        }}
                    >
                        Swing Trips
                    </p>
                    <p
                        style={{
                            // cursor: "pointer",
                            fontSize: "0.9rem",
                            marginBottom: ".9rem",
                            marginLeft: "1rem",
                            marginRight: "1rem",
                        }}
                    >
                        {" "}
                        &#8827;
                    </p>
                    <p
                        style={{
                            // cursor: "pointer",
                            fontSize: "0.7rem",
                            marginBottom: "1rem",
                        }}
                    >
                        Ride ID: {rideDetails?.id ? rideDetails.id : " "}
                    </p>
                </div>
                <h1>
                    Ride Details of{" "}
                    <span
                        style={{
                            backgroundColor: "yellow",
                        }}
                    >
                        #{rideDetails?.id ? rideDetails.id : " "}
                    </span>
                </h1>
                <SingleSwingRide
                    rideDetails={rideDetails}
                    location={location}
                />
            </AgenciesSection>
        </Layout>
    )
}


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SwingRide);