import React, { useState, useEffect } from "react";
import {
    Form
} from "../style/globalStyles";
import Moment from "moment";
import { useDispatch } from "react-redux";
import { Table } from "./Table";
import { TableStyles } from "../style/globalStyles";
import { getQuotes } from "../state"

export const SingleSwingRide = ({
    rideDetails = {},
    location,
}) => {
    rideDetails === null && typeof history !== "undefined" && history.go(-1);

    const [quotes, setQuotes] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (rideDetails.id) {
            dispatch(getQuotes(Math.trunc(rideDetails.id), (data) => { setQuotes(data) }, () => { }));
        }
    }, [rideDetails]);

    return (
        <>
            <Form>
                <div>
                    <h4
                        style={{
                            color: "lightseagreen",
                            width: "100%",
                            marginRight: "5rem",
                        }}
                    >
                        Basic ride details
                    </h4>
                    <br />
                    <br />
                    <div
                        style={{
                            marginTop: "1rem",
                            marginBottom: "2rem",
                            display: "flex",
                            flexWrap: "wrap",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                            }}
                        >
                            <label htmlFor="rideType">
                                Ride Type
                                <p>
                                    {rideDetails.rideType ? (rideDetails.rideType === "offer"
                                        ? "Vehicle Available"
                                        : "Need Vehicle") : "NA"}
                                </p>
                            </label>
                            <label htmlFor="createdBy">
                                Created By
                                <p>
                                    {rideDetails.createdByName ? rideDetails.createdByName : "NA"}
                                </p>
                            </label>
                            <label htmlFor="createdByPhone">
                                Created By Phone #
                                <p>
                                    {rideDetails.createdByPhoneNumber ? rideDetails.createdByPhoneNumber : "NA"}
                                </p>
                            </label>
                            <label htmlFor="vehicleCat">
                                Vehicle Category
                                <p>
                                    {rideDetails.vehicleCategoryName ? rideDetails.vehicleCategoryName : "NA"}
                                </p>
                            </label>
                            <label htmlFor="fromCity">
                                From City
                                <p>
                                    {rideDetails.fromCityName ? rideDetails.fromCityName : "NA"}
                                </p>
                            </label>
                            <label htmlFor="toCity">
                                To City
                                <p>
                                    {rideDetails.toCityName ? rideDetails.toCityName : "NA"}
                                </p>
                            </label>
                            <label htmlFor="startDate">
                                Start Date
                                <p>
                                    {rideDetails.startDate ? Moment(rideDetails.startDate, "YYYY-MM-DD HH:mm:ss").format("DD MMM, YYYY") : "NA"}
                                </p>
                            </label>
                            <label htmlFor="startTime">
                                Start Time
                                <p>
                                    {rideDetails.startDate ? Moment(rideDetails.startDate, "YYYY-MM-DD HH:mm:ss").format("hh:mm A") : "NA"}
                                </p>
                            </label>
                            <label htmlFor="endDate">
                                End Date
                                <p>
                                    {rideDetails.endDate ? Moment(rideDetails.endDate, "YYYY-MM-DD HH:mm:ss").format("DD MMM, YYYY") : "NA"}
                                </p>
                            </label>
                            <label htmlFor="endTime">
                                End Time
                                <p>
                                    {rideDetails.endDate ? Moment(rideDetails.endDate, "YYYY-MM-DD HH:mm:ss").format("hh:mm A") : "NA"}
                                </p>
                            </label>
                            <label htmlFor="tripCost">
                                Trip Cost
                                <p>
                                    {rideDetails.tripCost ? rideDetails.tripCost : "NA"}
                                </p>
                            </label>
                        </div>
                    </div>

                    <h4 style={{ color: "lightseagreen", width: "100%" }}>
                        Quotes{" "}
                    </h4>
                    <br />
                    <br />
                    <div
                        style={{ marginBottom: "2rem", display: "flex", flexWrap: "wrap" }}
                    >
                        {quotes.length > 0 ? <QuotesTable quotes={quotes} /> : "NO DATA"}
                    </div>
                </div>

                <div
                    style={{
                        marginBottom: "70vh",
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "column",
                    }}
                >
                    <label htmlFor="created">
                        Created on
                        <p>{rideDetails.createdOn ? Moment(rideDetails.createdOn, "YYYY-MM-DD HH:mm:ss").format("DD MMM, YYYY") : "NA"}</p>
                    </label>
                    <label htmlFor="updated">
                        Last updated on
                        <p>{rideDetails.updatedOn ? Moment(rideDetails.updatedOn, "YYYY-MM-DD HH:mm:ss").format("DD MMM, YYYY") : "NA"}</p>
                    </label>
                    <br />
                </div>
            </Form>
        </>
    )
}

const QuotesTable = ({ quotes }) => {

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                accessor: "userName",
            },
            {
                Header: "Phone Number",
                accessor: "userPhoneNumber",
            },
            {
                Header: "Vehicle Name",
                accessor: "vehicleModelName",
            },
            {
                Header: "Vehicle Model Year",
                accessor: (quote) =>
                    quote.vehicleModelYear
                        ? quote.vehicleModelYear
                        : "NA",
            },
            {
                Header: "Vehicle Category",
                accessor: "vehicleCategoryName",
            },
            {
                Header: "Quoted Amount",
                accessor: "quotedAmount",
            },
            {
                Header: "Status",
                accessor: (quote) =>
                    quote.isAccepted
                        ? "Accepted"
                        : "Pending / Not Accepted",
            },
        ],
        []
    );

    var data = React.useMemo(() => quotes, []);
    console.log("Quotes from table", data)
    var tableRender = (
        <Table
            getTdProps={(state, rowInfo, column, instance) => {
                return {
                    // onClick: (e) => console.log("row clicked", rowInfo),
                };
            }}
            // Pass the page url here to navigate to onClick
            extras={true}
            // path={"/ride"}
            columns={columns}
            data={quotes}
        />
    );

    return <TableStyles>{quotes ? tableRender : " "}</TableStyles>;
};